import "./App.css";
import DropdownNavbar from "./Components/DropdownNavbar/DropdownNavbar";
import Navbar from "./Components/Navbar";
import Main from "./Main/Main";
import { useState } from "react";

function App() {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  let onDropdownValueChange = (value) => {
    setIsDropdownOpen(value);
  }

  return (
    <div className="App">
      <Navbar  onHamburgerToggle = {onDropdownValueChange}/>
      <DropdownNavbar show = {isDropdownOpen}/>
      <Main />
    </div>
  );
}

export default App;
