import { Component } from "react";
import { social_media_icons } from "../../../../Configurations/Configs";
import SocialMediaIcon from "../../../NavbarItems/SocialMediaIcons/SocialMediaIcon/SocialMediaIcon";
import "./SocialMediaIcons.css"

class SocialMediaIcons extends Component
{
    render()
    {
        return(
            <div className="dropdown-social-media-icons">
                    {Object.entries(social_media_icons).map(([platform, url], index) => (
                        <SocialMediaIcon key={index} link={url}>{platform}</SocialMediaIcon>
                    ))}
            </div>
        );
    }
}

export default SocialMediaIcons