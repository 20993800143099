import { Component } from "react";
import "./TeamMember.css";

class TeamMember extends Component {
  render() {
    return (
      <div className="team-member-content">
        <img src={this.props.memberImage} alt={this.props.name}></img>
        <div className="team-member-details">
          <h1 className="team-member-name">{this.props.name}</h1>
          <p className="team-member-description">{this.props.children}</p>
        </div>
      </div>
    );
  }
}

export default TeamMember;
