import { Component } from "react";
import {items} from "../../../Configurations/Configs"
import "./DropDownNavbarItems.css"
import DropDownNavbarItem from "./DropDownNavbarItem/DropDownNavbarItem";
import SocialMediaIcons from "./SocialMediaIcons/SocialMediaIcons"; 

class DropDownNavbarItems extends Component
{
    render()
    {
        return(
        <div className="dropdown-items">
                {Object.entries(items).map(([label, link], index) => (
                    <DropDownNavbarItem key={index} link={link}>
                        {label}
                    </DropDownNavbarItem>
                ))}
                <SocialMediaIcons/>
        </div>
        )
    }
}

export default DropDownNavbarItems;