import { Component } from "react";
import "./DropDownNavbarItem.css"

class DropDownNavbarItem extends Component
{
    render()
    {
        return<a className = "dropdown-item chalk-underline" href={this.props.link}>
            {this.props.children}
        </a>
    }
}

export default DropDownNavbarItem