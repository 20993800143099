import Container from "../../Components/Container/Container";
import TeamMember from "../../Components/TeamMember/TeamMember";
import "./AboutUs.css";
const { Component } = require("react");

class AboutUs extends Component {
  render() {
    return (
      <div className="about-us">
        <Container
          image_url="https://placehold.co/1280x720/424242/424242"
          height="fit-content"
          padding="150px 0 100px 0"
          justifyContent="space-around"
        >
          <div className="about-us-details">
            <h1 className="about-us-title">About Us</h1>
            <p className="about-us-description">
              Welcome to Avyaan Studios, a vibrant indie game development studio
              headquartered in the historic city of Moradabad, India. Our
              passion lies in crafting immersive gaming experiences inspired by
              the timeless tales and rich heritage of Hindu mythology.
            </p>
            <p className="about-us-description">
              At Avyaan Studios, we blend storytelling with engaging gameplay,
              creating worlds that celebrate India's cultural legacy. We are
              currently working on an ambitious 2D Metroidvania game, bringing
              ancient legends to life in a way never seen before.
            </p>
            <p className="about-us-description">
              As an indie studio, our vision is to push the boundaries of
              creativity and innovation, delivering games that resonate with
              players worldwide. Stay tuned for updates as we embark on this
              exciting journey of myth and adventure!
            </p>
          </div>
        </Container>

        <Container
          height="fit-content"
          bg_color="#2a232b"
          justifyContent="flex-start"
          padding="70px 0"
        >
          
          <div className="our-team">
            <h1 className="team-title">Our Team</h1>
            <div className="team-members">
            <TeamMember
              memberImage="https://avatar.iran.liara.run/public/34"
              name="Jai"
            >
              <p>With over a decade of experience in the gaming industry, Jai has been at the forefront of creating immersive experiences across mobile, PC, and VR platforms. Proficient in a wide range of technologies, Jai has contributed to the development of numerous successful games, showcasing a deep understanding of both the creative and technical aspects of game design.</p>
              <p>Jai is passionate about the transformative power of games, believing they are not just a source of entertainment but a meaningful medium to express ideas and inspire the younger generation. Driven by this philosophy, Jai strives to craft games that resonate with players and leave a lasting impact.</p>
            </TeamMember>
            <TeamMember
              memberImage="https://avatar.iran.liara.run/public/74"
              name="Jhalak"
            >
              <p>Jhalak brings nearly 9 years of expertise in automation and manual testing, having honed her skills at some of the biggest names in the tech world. As the co-founder of Avyaan Studios, she plays a pivotal role in ensuring the studio's games meet the highest standards of quality and performance.</p>

              <p>With a sharp eye for detail and a passion for excellence, Jhalak leads the testing efforts, overseeing every aspect of the process to deliver seamless gaming experiences. Her dedication and leadership have been instrumental in shaping the studio's commitment to creating flawless games that captivate players worldwide.</p>
            </TeamMember>
          </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default AboutUs;
