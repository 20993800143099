import { Component } from "react";
import './DropdownNavbar.css'
import DropDownNavbarItems from "./DropDownNavbarItems/DropDownNavbarItems";

class DropdownNavbar extends Component
{
    render()
    {
        let classes = this.props.show ? "dropdown-navbar show" : "dropdown-navbar hide";
        return(
            <div className={classes}>
                <DropDownNavbarItems/>
            </div>
        )
    }
}


export default DropdownNavbar;